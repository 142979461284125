const hamburger = $('.hamburger');
const mainMenu = $('.mobile-menu');

$('button[data-menu-toggle], .mobile-menu ul a').on('click', function(){
    toggleMenu();
})

const toggleMenu = () => {
    if($(mainMenu).hasClass('is-active')){
        $(mainMenu).removeClass('is-active');
        $(hamburger).removeClass('is-active');
        $(header).removeClass('is-active');
        $('html, body').removeClass('no-scroll');
    } else {
        $(mainMenu).addClass('is-active');
        $(hamburger).addClass('is-active');
        $(header).addClass('is-active');
        $('html, body').addClass('no-scroll');
    }
}