const homepageImpact = $('section.section--impact_homepage');
const homepageImpactImage = $('section.section--impact_homepage .image');
const homepageImpactHeading = $('section.section--impact_homepage h1');
const homepageImpactText = $('section.section--impact_homepage p');
const homepageImpactFeather = $('section.section--impact_homepage svg');

$(homepageImpactHeading).html(wrapWordsInSpan($(homepageImpactHeading).text()));
$(homepageImpactText).html(wrapWordsInSpan($(homepageImpactText).text()));

const homepageImpactTimeline = anime.timeline();

homepageImpactTimeline.add({
    targets: homepageImpactImage[0],
    duration: 300,
    opacity: {
        value: [0, 1],
        easing: 'linear'
    },
    translateY: {
        value: ['5%', 0],
        easing: 'linear',
    }
})
.add({
    targets: '.section--impact_homepage h1 .word',
    delay: (el, i) => 75 * i,
    duration: 200,
    opacity: {
        value: [0, 1],
        easing: 'linear'
    },
    translateY: {
        value: ['10%', 0],
        easing: 'easeOutQuad',
    }
}).add({
    targets: '.section--impact_homepage p .word',
    delay: (el, i) => 25 * i,
    duration: 200,
    opacity: {
        value: [0, 1],
        easing: 'linear'
    },
    translateY: {
        value: ['10%', 0],
        easing: 'easeOutQuad',
    }
}).add({
    targets: homepageImpactFeather[0],
    duration: 1000,
    opacity: {
        value: [0, 1],
        easing: 'linear'
    },
    translateY: {
        value: ['-50%', 0],
        easing: 'easeOutQuad',
    }
}, ['-200'])