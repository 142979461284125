//-----------------------------------
// scripts.js
// Author: @codymarcoux (Studio123)
//
// NOTE: Main scripts file
//-----------------------------------

// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;

// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);

// We listen to the resize event
// Only want to resize the menu to prevent janky impact area
window.addEventListener('resize', () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
});

var header = document.querySelector("header.main-header");
var options = {
    offset : 100,
    tolerance : {
        up : 15,
        down : 10
    },
}
var headroom  = new Headroom(header, options);
headroom.init();


// Parallax
var rellax = new Rellax('.rellax');
