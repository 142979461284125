$('a[href*="#"]')
  .not('[href="#"]')
  .not('[href="#0"]')
  .click(function(event) {
    if (
      location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') 
      && 
      location.hostname == this.hostname
    ) {
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
      if (target.length) {
        event.preventDefault();

        isScrollingToTarget = true;

        anime({
            targets: 'html, body',
            scrollTop: target.offset().top,
            easing: 'spring(1, 95, 50, 0)',
            complete: () => {
              setTimeout(() => {
                isScrollingToTarget = false;
              }, 250)
            }
        })
      }
    }
  });